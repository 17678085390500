import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import styles from './work2.module.scss';
import galleryStyles from '../imageGalleryPreview/imageGalleryPreview.module.scss';
import Contact from '../../components/Contact/Contact';

// Import images
import heroImage from '../../assets/ex8.jpg';
import image1 from '../../assets/Work2-1.png';
import image2 from '../../assets/Work2-2.png';
import icon1 from '../../assets/workicon2-1.png';
import icon2 from '../../assets/workicon2-2.png';
import icon3 from '../../assets/workicon2-3.png';
import SolutionDiagram from '../SolutionDiagram';
interface WorkImage {
  id: number;
  src: string;
  alt: string;
  text: string;
}

interface CircleFeature {
  id: number;
  src: string;
  alt: string;
  title: string;
  description: string;
}

// 이미지 갤러리 인터페이스
interface ImageItem {
  id: number;
  src: string;
  alt: string;
  title: string;
  description: string;
}

const Work2: React.FC = () => {
  const { mode } = useSelector((state: RootState) => state.theme);
  const [imagesVisible, setImagesVisible] = useState<boolean[]>([false, false, false]);
  const [titleVisible, setTitleVisible] = useState<boolean>(false);
  const [descriptionVisible, setDescriptionVisible] = useState<boolean>(false);
  const [featuresVisible, setFeaturesVisible] = useState<boolean>(false);
  const [galleryVisible, setGalleryVisible] = useState<boolean>(false);
  const imagesRef = useRef<(HTMLDivElement | null)[]>([]);
  const titleRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const featuresRef = useRef<HTMLDivElement>(null);
  const galleryRef = useRef<HTMLDivElement>(null);
  
  // 이미지 갤러리 관련 상태
  const [selectedImage, setSelectedImage] = useState<ImageItem | null>(null);

  // 샘플 이미지 데이터
  const workImages: WorkImage[] = [
    {
      id: 1,
      src: image1,
      alt: "대시보드 메인 화면",
      text: "복잡하고, 방대한 스프레드 시트관리 CRM을 통해 쉽고 간단하게 해결하세요."
    },  
    {
      id: 2,
      src: image2,
      alt: "대시보드 메인 화면",
      text: "상태별로 나눠지고 동기화 되지 않아 발생되는 실수와 불필요한 에너지 소모를 미니마이즈로 해결하세요."
    }
  ];

  // 원형 이미지와 설명을 위한 데이터
  const circleFeatures: CircleFeature[] = [
    {
      id: 1,
      src: icon1,
      alt: "영업 프로세스 관리",
      title: "영업 프로세스 관리",
      description: "상담 내역, 진행 단계, 계약 전환(라이브)까지\n모든 흐름이 정리되고 시각화됩니다"
    },
    {
      id: 2,
      src: icon2,
      alt: "업무 배정 · 요청 · 승인까지 자동화",
      title: "업무 배정·요청·승인까지 자동화",
      description: "역할에 맞게 담당자 배정\n요청/승인도 시스템으로 흐름화"
    },
    {
      id: 3,
      src: icon3,
      alt: "성과 집계와 지표 시각화",
      title: "성과 집계와 지표 시각화",
      description: "실적(취급고), 업무량, 프로젝트 단위 기여도 등\n개인별/팀별 성과를 데이터로 확인합니다"
    }
  ];

  // 이미지 갤러리 데이터
  const galleryImages: ImageItem[] = [
    {
      id: 1,
      src: image1,
      alt: "고객 등록 및 영업 관리",
      title: "고객 등록 및 영업 관리",
      description: "고객 정보 입력 후 상담/진행 이력을 자동 기록\n각 영업 단계별 전환률도 함께 관리\n🡒 고객 등록 → 상담 기록 → 단계별 진행 추적"
    },
    {
      id: 2,
      src: image2,
      alt: "계약 전환 및 프로젝트 생성",
      title: "계약 전환 및 프로젝트 생성",
      description: "계약이 성사되면 프로젝트가 자동 생성되고\n담당자와 예상 목표가 함께 배정됩니다\n🡒 계약 승인 → 프로젝트 자동 생성 → 담당자 연결"
    },
    {
      id: 3,
      src: image1,
      alt: "업무 흐름과 요청 체계",
      title: "업무 흐름과 요청 체계",
      description: "프로젝트 단위 업무를 역할별로 분배하고\n협업 요청과 승인, 처리 이력을 기록합니다\n🡒 업무 배정 → 요청 등록 → 승인 및 처리 기록"
    },
    {
      id: 4,
      src: image2,
      alt: "성과 집계 및 접근 권한 제어",
      title: "성과 집계 및 접근 권한 제어",
      description: "팀장 이상은 지표와 보고서를 확인하고\n일반 사용자는 본인 업무 현황만 열람 가능\n알림/주의사항도 실시간으로 관리됩니다\n🡒 실적 집계 → 역할별 접근 → 시스템 알림 설정"
    }
  ];

  // 컴포넌트 마운트 시 첫 번째 이미지 선택
  useEffect(() => {
    if (galleryImages.length > 0) {
      setSelectedImage(galleryImages[0]);
    }
  }, []);

  // IntersectionObserver를 사용하여 요소가 화면에 보이는지 감지
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1 // 10% 이상 보이면 감지
    };

    // 타이틀 관찰자
    const titleObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setTitleVisible(true);
          titleObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 원형 이미지 섹션 관찰자
    const featuresObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setFeaturesVisible(true);
          featuresObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 이미지 관찰자
    const imageObservers = imagesRef.current.map((ref, index) => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setImagesVisible(prev => {
              const newState = [...prev];
              newState[index] = true;
              return newState;
            });
            observer.unobserve(entry.target);
          }
        });
      }, { ...observerOptions, threshold: 0.2 });
      return observer;
    });

    // 갤러리 관찰자
    const galleryObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setGalleryVisible(true);
          galleryObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 설명 관찰자
    const descriptionObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setDescriptionVisible(true);
          descriptionObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 각 요소에 관찰자 등록
    if (titleRef.current) {
      titleObserver.observe(titleRef.current);
    }

    if (featuresRef.current) {
      featuresObserver.observe(featuresRef.current);
    }

    imagesRef.current.forEach((ref, index) => {
      if (ref) imageObservers[index].observe(ref);
    });

    if (galleryRef.current) {
      galleryObserver.observe(galleryRef.current);
    }

    if (descriptionRef.current) {
      descriptionObserver.observe(descriptionRef.current);
    }

    // 컴포넌트 언마운트 시 관찰자 해제
    return () => {
      if (titleRef.current) titleObserver.unobserve(titleRef.current);
      
      if (featuresRef.current) featuresObserver.unobserve(featuresRef.current);
      
      imagesRef.current.forEach((ref, index) => {
        if (ref) imageObservers[index].unobserve(ref);
      });
      
      if (galleryRef.current) galleryObserver.unobserve(galleryRef.current);
      
      if (descriptionRef.current) descriptionObserver.unobserve(descriptionRef.current);
    };
  }, []);

  // 이미지 선택 핸들러
  const handleImageSelect = (image: ImageItem) => {
    setSelectedImage(image);
  };

  return (
    <div className={`${styles.work2Container} ${mode === 'dark' ? styles.dark : ''}`}>
        <div 
          ref={titleRef} 
          className={`${styles.titleSection} ${titleVisible ? styles.visible : ''}`}
        >
          <h1 className={styles.title}>CRM 기반 종합 업무관리 솔루션</h1>
          <div className={styles.overlay}></div>
          <img 
            src={heroImage} 
            alt="고객관리 CRM 프로그램" 
            className={styles.backgroundImage} 
          />
        </div>
        <p className={styles.subtitle} 
           dangerouslySetInnerHTML={{ 
             __html: "<span class=\"" + styles.impact + "\">엑셀과 감에 의존하던 영업 관리를 시스템화!</span><br />고객 유치부터 계약, 실적까지 한눈에<br /><span class=\"" + styles.impact + "\">업무 배정·협업 요청·승인 체계까지 자동화</span><br />minimize의 CRM 솔루션으로 성과가 보이는 팀을 만드세요" 
           }}>
        </p>
      
      {/* 원형 이미지 섹션 */}
      <div
        ref={featuresRef}
        className={`${styles.featuresSection} ${featuresVisible ? styles.visible : ''}`}
      >
        <div className={styles.featuresContainer}>
          {circleFeatures.map((feature, index) => (
            <div 
              key={feature.id} 
              className={styles.featureItem}
              style={{ animationDelay: `${index * 0.2}s` }}
            >
              <div className={styles.circleImageContainer}>
                <img src={feature.src} alt={feature.alt} className={styles.circleImage} />
              </div>
              <div className={styles.featureContent}>
                <h3 className={styles.featureTitle}>{feature.title}</h3>
                <p className={styles.featureDescription}>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* 이미지 갤러리 - 줄바꿈 적용 */}
      <div 
        ref={galleryRef}
        className={`${styles.galleryPreviewSection} ${galleryVisible ? styles.visible : ''}`}
      >
        <div className={galleryStyles.galleryContainer}>
          <div className={galleryStyles.galleryWrapper}>
            {/* 왼쪽 섹션 - 큰 이미지 */}
            <div className={galleryStyles.leftSection}>
              <div className={galleryStyles.mainImageWrapper}>
                {selectedImage && (
                  <img 
                    src={selectedImage.src} 
                    alt={selectedImage.alt}
                    className={galleryStyles.mainImage}
                  />
                )}
              </div>
            </div>
            
            {/* 오른쪽 섹션 - 선택된 이미지의 번호, 제목과 설명 */}
            <div className={galleryStyles.rightSection}>
              {selectedImage && (
                <>
                  <span className={galleryStyles.imageNumber}>0{selectedImage.id}</span>
                  <h2 className={galleryStyles.imageTitle}>{selectedImage.title}</h2>
                  <p className={galleryStyles.imageDescription}>
                    {selectedImage.description.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < selectedImage.description.split('\n').length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </p>
                </>
              )}
              
              {/* 가로로 정렬된 썸네일 컨테이너 */}
              <div className={galleryStyles.thumbnailContainer}>
                {galleryImages.map((image) => (
                  <div 
                    key={image.id}
                    className={`${galleryStyles.thumbnailItem} ${selectedImage && selectedImage.id === image.id ? galleryStyles.active : ''}`}
                    onClick={() => handleImageSelect(image)}
                  >
                    <div className={galleryStyles.thumbnailImageWrapper}>
                      <img 
                        src={image.src} 
                        alt={image.alt}
                        className={galleryStyles.thumbnailImage}
                      />
                    </div>
                    <div className={galleryStyles.thumbnailContent}>
                      <span className={galleryStyles.thumbnailNumber}>0{image.id}</span>
                      <h3 className={galleryStyles.thumbnailTitle}>{image.title}</h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.imageGallery}>
        {workImages.map((image, index) => (
          <div 
            key={image.id}
            ref={(el) => { imagesRef.current[index] = el }}
            className={`${styles.imageContainer} ${imagesVisible[index] ? styles.visible : ''}`}
            style={{ animationDelay: `${index * 0.2}s` }}
          >
            <div className={styles.imageWrapper}>
              <img src={image.src} alt={image.alt} className={styles.workImage} />
            </div>
            <p className={styles.imageText}>{image.text}</p>
          </div>
        ))}
      </div>
      
      <div 
        ref={descriptionRef}
        className={`${styles.descriptionSection} ${descriptionVisible ? styles.visible : ''}`}
      >
        <div className={styles.descriptionWrapper}>
          <h2 className={styles.descriptionTitle}>프로젝트 상세 설명</h2>
          <p className={styles.descriptionText}>
            미니마이즈 CRM은 방대하고 복잡하게 나누어져 있던 구글 스프레드시트의 단점을 극복하고 장점을 살려 술루션을 구현하였습니다.
            기존의 복잡하던 엑셀 방식의 업무에서 벗어나 직관적인 UI를 통하여 실수와 중복으로 소비되는 시간을 줄이고
            사용자 경험에 맞는 인터페이스로 업무 난이도를 낮췄습니다.
          </p>
          <p className={styles.descriptionText}>
            직관적고 사용자의 경험에 맞는 UI로 귀사만의 솔루션을 구현해드립니다. 정보관리부터 업무 정산까지 CRM으로 한번에 해결해보세요.         
          </p>
        </div>
      </div>
      
      <Contact 
        backgroundColor={mode === 'dark' ? '#2a2a2a' : '#f9f9f9'}
        textColor={mode === 'dark' ? '#f0f0f0' : '#333'}
      />
    </div>
  );
};

export default Work2;
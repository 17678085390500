import Img1 from '../assets/about0.png'
import Img2 from '../assets/about1.png'
import Img3 from '../assets/about2.png'
import Img4 from '../assets/about3.png'
import Img5 from '../assets/about4.png'
import Img6 from '../assets/about5.png'
import Img7 from '../assets/about6.png'
import Img8 from '../assets/about7.png'
import Img9 from '../assets/about8.png'
import Img10 from '../assets/about9.png'
import Img11 from '../assets/about10.png'
import Img12 from '../assets/about11.png'
import Img13 from '../assets/about12.png'

export interface SlideContent {
  id: number;
  title?: string;
  description?: string;
  backgroundImage?: string;
  customText?: string[];
  leftPanel: {
    title: string;
    subtitle?: string;
    backgroundImage?: string;
  };
}

export const whatWeDoData: SlideContent[] = [
  {
    id: 1,
    backgroundImage: Img2,
    description: '일의 본질이 드러납니다.\n불필요한 단계와 반복이 보입니다.',
    leftPanel: {
      title: '',
      subtitle: '업무를 이해합니다.\n현재 일의 흐름을 함께 살펴봅니다.',
      backgroundImage: Img3
    }
  },
  {
    id: 2,
    backgroundImage: Img4,
    description: '줄일 수 있는 지점이 보입니다.\n자동화 외에도 더 나은 구조를 그립니다.',
    leftPanel: {
      title: '',
      subtitle: '문제를 분석합니다.\n어떤 부분이 시간과 에너지를 잡아먹는지 파악합니다.',
      backgroundImage: Img5
    }
  },
  {
    id: 3,
    backgroundImage: Img6,
    description: '일의 흐름이 단순해집니다.\n사용자 입장에서 가장 자연스러운 방식으로.',
    leftPanel: {
      title: '',
      subtitle: '새로운 흐름을 설계합니다.\n기존 방식을 그대로 따르지 않습니다.',
      backgroundImage: Img7
    }
  },
  {
    id: 4,
    backgroundImage: Img8,
    description: '익숙한 방식으로, 새로 배울 필요 없이.\n사용하는 사람 중심으로 설계됩니다.',
    leftPanel: {
      title: '',
      subtitle: '맞춤형 도구를 개발합니다.\n고객 환경에 꼭 맞는 솔루션을 만듭니다.',
      backgroundImage: Img9
    }
  }, 
  {
    id: 5,
    backgroundImage: Img11,
    description: '즉시 효과를 확인할 수 있습니다.\n줄어든 업무, 줄어든 실수, 줄어든 시간.',
    leftPanel: {
      title: '',
      subtitle: '빠르게 적용합니다.\n프로토타입으로 빠르게 실행해봅니다.',
      backgroundImage: Img10
    }
  },
  {
    id: 6,
    backgroundImage: Img13,
    description: '지속 가능한 단순함을 완성합니다.\n시간이 지날수록 더 편해집니다.',
    leftPanel: {
      title: '',
      subtitle: '함께 개선해나갑니다.\n실제 사용 후 피드백을 반영합니다.',
      backgroundImage: Img12
    }
  }
];